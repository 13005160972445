import React from 'react'

import { ROOT_URL, ALLOWED_LANGUAGES } from 'constants/general.constants'
import { setSearchParams } from 'utils/queryString.utils'

import elopageLogoBlack from '../../assets/svg/elopage-dark.svg'

import './app-header.scss'

const AppHeader = () => {
  const changeLocale = (locale) => setSearchParams({ locale })

  const Lang = (
    <div className='app-header__language-selector'>
      {Object.values(ALLOWED_LANGUAGES).map((lang) => (
        <span
          key={lang.value}
          className={I18n.locale === lang.value ? 'app-header__language-selector--active' : ''}
          onClick={() => changeLocale(lang.value)}
        >
          {lang.value}
        </span>
      ))}
    </div>
  )
  return (
    <div className='app-header'>
      <div className='app-header__top-container'>{Lang}</div>
      <div className='app-header__bottom-container'>
        <a href={ROOT_URL}>
          <img className='app-header__logo' src={elopageLogoBlack} alt='logo' />
        </a>
      </div>
    </div>
  )
}

export default AppHeader
