import { action, observable, makeObservable } from 'mobx'

class AppStore {
  @observable useCases = [
    {
      url: 'https://pages.elopage.com/anleitungen-verkaufen',
      slug: 'anleitungen-verkaufen',
      name: I18n.t('react.app.header.anleitungen_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/coaching-business',
      slug: 'coaching-business',
      name: I18n.t('react.app.header.coaching_business'),
    },
    {
      url: 'https://pages.elopage.com/digitale-produkte-verkaufen',
      slug: 'digitale-produkte-verkaufen',
      name: I18n.t('react.app.header.digitale_produkte_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/ebooks-verkaufen',
      slug: 'ebooks-verkaufen',
      name: I18n.t('react.app.header.ebooks_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/tickets-verkaufen',
      slug: 'tickets-verkaufen',
      name: I18n.t('react.app.header.tickets_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/formulare-verkaufen',
      slug: 'formulare-verkaufen',
      name: I18n.t('react.app.header.formulare_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/gutscheine-verkaufen',
      slug: 'gutscheine-verkaufen',
      name: I18n.t('react.app.header.gutscheine_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/mitgliederbereich-erstellen',
      slug: 'mitgliederbereich-erstellen',
      name: I18n.t('react.app.header.mitgliederbereich_erstellen'),
    },
    {
      url: 'https://pages.elopage.com/online-kongresse-erstellen',
      slug: 'online-kongresse-erstellen',
      name: I18n.t('react.app.header.online_kongresse_erstellen'),
    },
    {
      url: 'https://pages.elopage.com/online-kurse-verkaufen',
      slug: 'online-kurse-verkaufen',
      name: I18n.t('react.app.header.online_kurse_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/online-verkaufen',
      slug: 'online-verkaufen',
      name: I18n.t('react.app.header.online_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/provisionsabrechnung-multi-level-marketing',
      slug: 'provisionsabrechnung-multi-level-marketing',
      name: I18n.t('react.app.header.provisionsabrechnung_multi_level_marketing'),
    },
    {
      url: 'https://pages.elopage.com/ratgeber-verkaufen',
      slug: 'ratgeber-verkaufen',
      name: I18n.t('react.app.header.ratgeber_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/software-verkaufen',
      slug: 'software-verkaufen',
      name: I18n.t('react.app.header.software_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/tutorials-verkaufen',
      slug: 'tutorials-verkaufen',
      name: I18n.t('react.app.header.tutorials_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/videokurse-verkaufen',
      slug: 'videokurse-verkaufen',
      name: I18n.t('react.app.header.videokurse_verkaufen'),
    },
    {
      url: 'https://pages.elopage.com/webinare-verkaufen',
      slug: 'webinare-verkaufen',
      name: I18n.t('react.app.header.webinare_verkaufen'),
    },
  ]

  @observable loading = true
  @observable layoutSettings = {
    hideHeader: false,
    hideFooter: false,
  }

  @action setLayoutSettings = (settings) => {
    this.layoutSettings = settings
  }

  constructor() {
    makeObservable(this)
  }
}

export default new AppStore()
